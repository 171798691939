import { defineStore } from 'pinia'
import { supabase } from "../supabase/init";

export const useStatsStore = defineStore('stats', {
    state: () => {
        return {
            id: 0,
            daysInRow: 0,
            daysCompleted: 0,
            daysTotal: 0,
            todayAllDone: 0,
            dailyScore: 0,
            weeksInRow: 0,
            weeksCompleted: 0,
            weeksTotal: 0,
            thisWeekAllDone: 0,
            weeklyScore: 0,
            statsLoaded: false,
        }
    },
    getters: {
        calcDaysInRow: (state) => ((state.daysInRow + state.todayAllDone)),
        calcWeeksInRow: (state) => ((state.weeksInRow + state.thisWeekAllDone)),
        daysPercentage: (state) => ((state.daysCompleted + state.todayAllDone) / state.daysTotal * 100).toFixed(1),
        dayMaster: (state) => (state.daysCompleted + state.todayAllDone),
        monthMaster: (state) => (Math.floor((state.daysCompleted + state.todayAllDone) / 30)),
        weeksPercentage: (state) => ((state.weeksCompleted + state.thisWeekAllDone) / state.weeksTotal * 100).toFixed(1),
        weekMaster: (state) => (state.weeksCompleted + state.thisWeekAllDone),
        yearMaster: (state) => (Math.floor((state.daysCompleted + state.todayAllDone) / 365)),
    },
    actions: {
        async getStats () {
            try {
                const {data: stats, error} = await supabase
                    .from("profile_team")
                    .select("*")
                    .eq("profile_id", supabase.auth.session().user.id)
                    .eq("team_id", localStorage.getItem("currentTeam"))
                    .single()
                if (error) throw error;
                this.id = stats.id;
                this.daysInRow = stats.days_in_row;
                this.daysCompleted = stats.days_completed;
                this.daysTotal = stats.days_total;
                this.todayAllDone = stats.today_all_done;
                this.dailyScore = stats.daily_score;
                this.weeksInRow = stats.weeks_in_row;
                this.weeksCompleted = stats.weeks_completed;
                this.weeksTotal = stats.weeks_total;
                this.thisWeekAllDone = stats.thisweek_all_done;
                this.weeklyScore = stats.weekly_score;
                this.statsLoaded = true;
            } catch (error) {
                console.warn(error.message);
            }
        },
        async updateScore(intv, count) {
            try {
                if (intv == 1) {
                    const {error} = await supabase
                        .from("profile_team")
                        .update({
                            daily_score: this.dailyScore + count,
                        })
                        .eq("profile_id", supabase.auth.session().user.id)
                        .eq("team_id", localStorage.getItem("currentTeam"));
                    if (error) throw error;
                } else if (intv == 7) {
                    const {error} = await supabase
                        .from("profile_team")
                        .update({
                            weekly_score: this.weeklyScore + count
                        })
                        .eq("profile_id", supabase.auth.session().user.id)
                        .eq("team_id", localStorage.getItem("currentTeam"));
                    if (error) throw error;
                }
            } catch (error) {
                console.log(error)
            }
        },
        async updateAllDoneDaily(allDone) {
            try {
                const { error } = await supabase
                    .from("profile_team")
                    .update({
                        today_all_done: allDone,
                    })
                    .eq("profile_id", supabase.auth.session().user.id)
                    .eq("team_id", localStorage.getItem("currentTeam"));
                if (error) throw error;
            } catch (error) {
                console.log(error)
            }
        },
        async updateAllDoneWeekly(allDone) {
            try {
                const { error } = await supabase
                    .from("profile_team")
                    .update({
                        thisweek_all_done: allDone,
                    })
                    .eq("profile_id", supabase.auth.session().user.id)
                    .eq("team_id", localStorage.getItem("currentTeam"));
                if (error) throw error;
            } catch (error) {
                console.log(error)
            }
        },
    }
})