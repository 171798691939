<script setup>

import {  onIonViewDidEnter,  IonGrid, IonRow, IonCol, IonCard, IonCardSubtitle, IonCardContent, IonCardHeader} from '@ionic/vue';
import { storeToRefs } from "pinia";

import {useStatsStore} from "@/store/useStats";
const stats = useStatsStore();

const {calcDaysInRow, dayMaster, daysTotal, daysPercentage, dailyScore, calcWeeksInRow, weekMaster, weeksTotal, weeksPercentage, weeklyScore, monthMaster, yearMaster, statsLoaded} = storeToRefs(stats);

onIonViewDidEnter(() => {
  stats.getStats()
})

</script>

<template>
    <ion-grid v-if="statsLoaded">
      <ion-row>
        <ion-col size="12" size-sm>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle class="font-open">
                {{ $t("card.stats") }}
              </ion-card-subtitle>
              {{ $t("card.days") }}
            </ion-card-header>
            <ion-card-content>
              {{ $t("card.daysInRow") }}: {{ calcDaysInRow }}<br>
              {{ $t("card.daysTotal") }}: {{ daysTotal }}<br>
              {{ $t("card.daysPerc") }}: {{ daysPercentage }} %<br>
              {{ $t("card.tasksDone") }}: {{dailyScore}}<br>
              <div  v-if="dayMaster > 0"
                    class="mt-5 mr-1 text-center master-div">
                <img src="../../assets/images/daymaster.svg" class="master-img">
                {{ dayMaster }} x<br>
              </div>
              <div  v-if="monthMaster > 0"
                    class="mt-5 text-center master-div">
                <img src="../../assets/images/monthmaster.svg" class="master-img">
                {{monthMaster }} x<br>
              </div>
              <div  v-if="yearMaster > 0"
                    class="mt-5 text-center master-div">
                <img src="../../assets/images/yearmaster.svg" class="master-img">
                {{yearMaster }} x<br>
              </div>
            </ion-card-content>
          </ion-card>
        </ion-col>
        <ion-col size="12" size-sm>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle class="font-open">
                {{ $t("card.stats") }}
              </ion-card-subtitle>
              {{ $t("card.weeks") }}
            </ion-card-header>
            <ion-card-content>
              {{ $t("card.weeksInRow") }}: {{ calcWeeksInRow}}<br>
              {{ $t("card.weeksTotal") }}: {{ weeksTotal }}<br>
              {{ $t("card.weeksPerc") }}: {{ weeksPercentage }} %<br>
              {{ $t("card.tasksDone") }}: {{weeklyScore}}<br>
              <div  v-if="weekMaster > 0"
                    class="mt-5 mr-1 text-center master-div">
                <img src="../../assets/images/weekmaster.svg" class="master-img">
                {{ weekMaster }} x<br>
              </div>
              <div  v-if="yearMaster > 0"
                    class="mt-5 text-center master-div">
                <img src="../../assets/images/yearmaster.svg" class="master-img">
                {{yearMaster }} x<br>
              </div>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
</template>

<style scoped>
.master-img {
  height: 75px;
}
.master-div {
  display: inline-block;
  max-width: 75px;
}
</style>