<script setup>
import {ref} from "vue";
import { storeToRefs } from "pinia";
import {supabase} from "@/supabase/init";
import { onIonViewWillEnter, IonSpinner, IonRow, IonCol,
  IonCard, IonCardSubtitle, IonCardContent, IonCardHeader} from '@ionic/vue';
import Task from "./Components/TaskComponent.vue";
import Stats from './Components/StatsComponent';
import ManageTasksButton from "./Components/ManageTasksButton";
import PageGridLayout from "@/views/Layouts/PageGridLayout";
import IonRefresh from "@/views/Components/IonRefresh";
import NoTeamAlert from "@/views/Components/NoTeamAlert";
import NoTeamMembersAlert from "@/views/Components/NoTeamMembersAlert";

import { useI18n } from "vue-i18n";
const i18nLocale = useI18n();
const locale = i18nLocale.locale.value;

import {useProfileStore} from "@/store/useProfile";
const profile = useProfileStore();
const {name, currentTeam, profileLoaded} = storeToRefs(profile);

import {useStatsStore} from "@/store/useStats";
const stats = useStatsStore();

import {useTaskStore} from "@/store/useTasks";
import CoffeeButton from "@/views/Components/CoffeeButton";
const tasks = useTaskStore();
const {dailyTasks, weeklyTasks, dailyLoaded, weeklyLoaded} = storeToRefs(tasks);

// Date
const dateToday = ref(new Date());
const dateOptions = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' };
const date = ref(dateToday.value.toLocaleDateString(locale + '-' + locale, dateOptions));

const setDate = () => {
  dateToday.value = new Date();
  date.value = dateToday.value.toLocaleDateString(locale + '-' + locale, dateOptions);
}

const listenToStatsUpdates = () => {
  supabase
      .from('profile_team:profile_id=eq.' + supabase.auth.session().user.id)
      .on('UPDATE', stats.getStats)
      .subscribe()
};

const loadData = async () => {
  const loadProfile = await profile.getProfile();
  const loadTasks = await tasks.getAllTasks(loadProfile);
  const loadStats = await stats.getStats(loadTasks);
  await listenToStatsUpdates(loadStats);
  setDate();
};

const doRefresh = (event) => {
  setTimeout(() => {
    tasks.getAllProfileTasks();
    event.target.complete();
    setDate();
  }, 1000);
}

onIonViewWillEnter(() => {
  loadData();
})

</script>

<template>
  <PageGridLayout>

    <!-- Refresher -->

    <template v-slot:refresher>
      <IonRefresh @ionRefresh="doRefresh($event)"></IonRefresh>
    </template>

    <!--Alerts -->

    <template v-slot:alerts>
      <NoTeamAlert></NoTeamAlert>
      <NoTeamMembersAlert></NoTeamMembersAlert>
    </template>

          <!-- If active team -->

          <ion-row v-if="profileLoaded && currentTeam">

            <!-- Check if daily data loaded -->

            <ion-col v-if="dailyLoaded" size="12" size-sm class="container">
              <ion-card>
                <ion-card-header>
                  {{ $t("card.today") }}
                  <ion-card-subtitle class="font-open">
                    {{name}}
                    <span class="float-right">{{date}}</span>
                  </ion-card-subtitle>
                </ion-card-header>
                <ion-card-content>

                  <!-- If no daily tasks -->

                  <template v-if="dailyTasks.length === 0">
                      <div>{{ $t("card.noDailyTasks") }}</div>
                  </template>

                  <!-- If daily tasks -->

                  <template v-else>
                    <Task
                        v-for="(task, index) in dailyTasks"
                        :key="index+task.name+task.status"
                        :id="task.id"
                        :name="task.name"
                        :status="task.status"
                        :intv="task.intv"
                        :editable="false"
                        :clickable="true"
                    >
                    </Task>
                  </template>

                  <ManageTasksButton v-if="currentTeam"></ManageTasksButton>

                </ion-card-content>
              </ion-card>
            </ion-col>


            <!-- If reloading tasks -->

            <ion-col v-else size="12" size-sm class="container">
              Reloading...<br><br>
              <ion-spinner></ion-spinner>
            </ion-col>

            <!-- Check if weekly data loaded -->

            <ion-col v-if="weeklyLoaded" class="container">

              <!-- Weekly tasks -->

              <ion-card>
                <ion-card-header>
                  {{ $t("card.thisWeek") }}
                  <ion-card-subtitle class="font-open">
                    {{name}}
                    <span class="float-right">{{ $t("card.mondaySunday") }}</span>
                  </ion-card-subtitle>
                </ion-card-header>
                <ion-card-content>

                  <!-- If no weekly tasks -->

                  <template v-if="weeklyTasks.length === 0">
                    <div>{{ $t("card.noWeeklyTasks") }}</div>
                  </template>

                  <!-- If weekly tasks -->

                  <template v-else>
                    <Task
                        v-for="(task, index) in weeklyTasks"
                        :key="index+task.status"
                        :id="task.id"
                        :name="task.name"
                        :status="task.status"
                        :intv="task.intv"
                        :editable="false"
                        :clickable="true"
                    >
                    </Task>
                  </template>
                  <ManageTasksButton v-if="currentTeam"></ManageTasksButton>
                </ion-card-content>
              </ion-card>
            </ion-col>

            <!-- If reloading -->

            <ion-col v-else class="container">
              Reloading...<br><br>
              <ion-spinner></ion-spinner>
            </ion-col>
          </ion-row>

    <!-- Buy me a coffee -->

    <CoffeeButton></CoffeeButton>

    <!-- Stats-->

          <ion-row>
            <div v-if="profileLoaded && currentTeam" class="container mt-5">
              <Stats></Stats>
            </div>
          </ion-row>

  </PageGridLayout>
</template>