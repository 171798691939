<script setup>
import {ref} from "vue";
import PageGridLayout from "@/views/Layouts/PageGridLayout";
import Task from "./Components/TaskComponent.vue";
import { IonRow, IonCol, onIonViewWillEnter } from '@ionic/vue';

import { supabase } from "../supabase/init";

import {useProfileStore} from "@/store/useProfile";
const profile = useProfileStore();


import { storeToRefs } from "pinia";
import {useTaskStore} from "@/store/useTasks";
const tasks = useTaskStore();
const {dailyTeamTasks, weeklyTeamTasks, dailyTeamLoaded, weeklyTeamLoaded} = storeToRefs(tasks);

const dailyTaskName = ref(null);
const weeklyTaskName = ref(null);
const taskName = ref(null);
const taskInterval = ref("select-interval");

const errorMsg = ref(null);

// Create task
const createTask = async () => {
  try {
    const { error } = await supabase.from("tasks").insert([
      {
        team_id: profile.currentTeam,
        name: taskName.value,
        intv: taskInterval.value,
        status: 0
      },
    ]);
    if (error) throw error;
    taskInterval.value = "select-interval";
    tasks.getAllTeamTasks();
    dailyTaskName.value = null;
    weeklyTaskName.value = null;
  } catch (error) {
    errorMsg.value = `Error: ${error.message}`;
    setTimeout(() => {
      errorMsg.value = "";
    }, 3000);
  }
};

async function submitDailyTask () {
  taskInterval.value = 1;
  taskName.value = dailyTaskName.value;
  createTask();
}

async function submitWeeklyTask () {
  taskInterval.value = 7;
  taskName.value = weeklyTaskName.value;
  createTask();
}

const loadData = async () => {
  await profile.getProfile();
  tasks.getTeamTasks(1);
  tasks.getTeamTasks(7);
};

onIonViewWillEnter(() => {
  loadData();
})

</script>

<template>
  <PageGridLayout>
        <ion-row>

            <!-- Daily tasks -->

              <ion-col v-if="dailyTeamLoaded" size="12" size-sm class="container">
                <ion-card lines="full">
                  <ion-card-header class="text-xl">

                    <!-- No daily tasks -->

                    <template v-if="dailyTeamTasks.length === 0">
                      <div class="w-full flex flex-col">
                        <h1 class="text-xl">Füge Deine erste tägliche Aufgabe hinzu!</h1>
                        <div class="text-sm text-light-grey">Neue Aufgaben werden automatisch verteilt.</div>
                      </div>
                    </template>

                    <!-- If daily tasks -->

                    <template v-else>
                      <div>{{ $t("manageTasks.dailyTasks") }}</div>
                      <div class="text-sm text-light-grey">{{ $t("manageTasks.remixDaily") }}</div>
                    </template>
                  </ion-card-header>
                  <ion-card-content>
                    <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
                      <Task
                          v-for="(task, index) in dailyTeamTasks"
                          :key="index"
                          :id="task.id"
                          :userId="task.profile_id"
                          :name="task.name"
                          :status="task.status"
                          :intv="task.intv"
                          :editable=true
                          :clickable=true
                          @deleted="getAllTasks()"
                          @edited="getAllTasks()"
                      >
                      </Task>
                    </div>

                    <!-- Create new daily task -->

                    <div v-if="!dailySubmitMsg" class="container mt-3">
                        <form @submit.prevent="submitDailyTask">
                          <input
                              type="text"
                              class="input"
                              id="task-name"
                              v-model="dailyTaskName"
                              :placeholder="$t('manageTasks.placeholderDaily')"
                          />
                          <button type="submit" class="update-btn">Save</button>
                        </form>
                    </div>
                    <div v-else class="container mt-3">
                      <h1 class="text-xl">{{dailySubmitMsg}}</h1>
                    </div>
                  </ion-card-content>
                </ion-card>
              </ion-col>

          <ion-col v-else size="12" size-sm class="container mt-12">
            <div>
              <ion-spinner></ion-spinner>
            </div>
          </ion-col>

            <!-- Weekly tasks -->

            <ion-col v-if="weeklyTeamLoaded" size="12" size-sm class="container">
              <ion-card lines="full">
                <ion-card-header class="text-xl">

            <!-- No weekly tasks-->

                    <template v-if="weeklyTeamTasks.length === 0">
                      <div class="w-full flex flex-col">
                        <h1 class="text-xl">Füge Deine erste wöchentliche Aufgabe hinzu!</h1>
                        <div class="text-sm text-light-grey">Neue Aufgaben werden automatisch verteilt.
                      </div>
                      </div>
                    </template>

                  <!-- If weekly tasks-->

                  <template v-else>
                    <div>{{ $t("manageTasks.weeklyTasks") }}</div>
                    <div class="text-sm text-light-grey">{{ $t("manageTasks.remixWeekly") }}
                    </div>
                  </template>
                </ion-card-header>
                <ion-card-content>
                  <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
                    <Task
                        v-for="(task, index) in weeklyTeamTasks"
                        :key="index+task.name+task.status"
                        :id="task.id"
                        :userId="task.profile_id"
                        :name="task.name"
                        :status="task.status"
                        :intv="task.intv"
                        :editable=true
                        :clickable=true
                        @deleted="getAllTasks()"
                        @edited="getAllTasks()"
                    >
                    </Task>
                  </div>

                  <!-- Create new weekly task -->

                  <div v-if="!weeklySubmitMsg" class="container mt-3">
                      <form @submit.prevent="submitWeeklyTask">
                        <input
                            type="text"
                            class="input"
                            id="task-name"
                            v-model="weeklyTaskName"
                            v-bind:placeholder="$t('manageTasks.placeholderWeekly')"
                        />
                        <input type="hidden" value="7">
                        <button
                            type="submit"
                            class="update-btn"
                        >Save
                        </button>
                      </form>
                  </div>
                  <div v-else class="container mt-3">
                    <h1 class="text-xl">{{weeklySubmitMsg}}</h1>
                    </div>
                </ion-card-content>
              </ion-card>
            </ion-col>

          <ion-col v-else size="12" size-sm class="container mt-12">
            <div>
              <ion-spinner></ion-spinner>
            </div>
          </ion-col>

        </ion-row>
  </PageGridLayout>
</template>