<script setup>
import { ref, watch } from "vue";
import { useToast } from "vue-toastification";
import { storeToRefs } from "pinia";
import { supabase } from "@/supabase/init";
import PageGridLayout from "@/views/Layouts/PageGridLayout";
import EditButton from "@/views/Components/EditButton";
import SaveButton from "@/views/Components/SaveButton";
import CloseButton from "@/views/Components/CloseButton";
import LogoutButton from "@/views/Components/LogoutButton";
import ManageTeamsButton from "@/views/Components/ManageTeamsButton";
import { onIonViewWillEnter, IonCard, IonCardHeader, IonCardContent, IonRow, IonCol } from '@ionic/vue';

const user = supabase.auth.user();
const toast = useToast();

// User data
import { useProfileStore } from "@/store/useProfile";
const profile = useProfileStore();
const { dailyEmailNotification, profileLoaded } = storeToRefs(profile);

import { useTeamStore } from "@/store/useTeam";
const team = useTeamStore();

const newName = ref("");
const newEmail = ref("");
const newPassword = ref("");
const passwordConfirm = ref("");

const editModeName = ref(false);
const toggleEditModeName = () => {
  editModeName.value = !editModeName.value;
};

const editModeEmail = ref(false);
const toggleEditModeEmail = () => {
  editModeEmail.value = !editModeEmail.value;
};

const editModePassword = ref(false);
const toggleEditModePassword = () => {
  editModePassword.value = !editModePassword.value;
};

const updateName = async () => {
  try {
    const { error } = await supabase
        .from("profiles")
        .update({ name: newName.value })
        .eq("id", user.id);
    if (error) throw error;
    toggleEditModeName();
    await loadData();
  } catch (error) {
    console.log(error);
  }
};

const updateEmail = async () => {
  try {
    const { error } = await supabase.auth.update({
      email: newEmail.value
    });
    if (error) throw error;
    toast("Email sent! Please confirm the change!");
    toggleEditModeEmail();
    await loadData();
  } catch (error) {
    console.log(error);
  }
};

const savePassword = async () => {
  if (newPassword.value === passwordConfirm.value) {
    try {
      const { user, error } = await supabase.auth.update({ password: newPassword.value });
      toast("Password changed!");
      toggleEditModePassword();
      await loadData();
      console.log(user, error);
    } catch (error) {
      console.log(error);
    }
  } else {
    toast("Passwords do not match!");
  }
};

const updateDailyEmailNotification = async () => {
  try {
    const { error } = await supabase
        .from("profiles")
        .update({ email_notification: dailyEmailNotification.value })
        .eq("id", user.id);
    console.log(user.id);
    if (error) throw error;
    toast("Daily email notification preference updated!");
  } catch (error) {
    console.log(error);
  }
};

// Team data

const loadData = async () => {
  const loadProfile = await profile.getProfile();
  await team.getTeam(loadProfile);
  const token = JSON.parse(localStorage.getItem('supabase.auth.token'));
  newEmail.value = token.currentSession.user.email;
  newName.value = profile.name; // Initialize newName with profile name
  dailyEmailNotification.value = profile.dailyEmailNotification; // Load the checkbox state from profile
};

onIonViewWillEnter(() => {
  loadData();
});

// Watch profileLoaded to ensure the data is loaded before reflecting the state
watch(profileLoaded, (newVal) => {
  if (newVal) {
    loadData();
  }
});


</script>

<template>
  <PageGridLayout>
    <!-- Edit user data -->
    <ion-row>
      <ion-col>
        <ion-card lines="full" v-if="profileLoaded">
          <ion-card-header class="text-xl uppercase">{{ $t("card.yourAccount") }}</ion-card-header>
          <ion-card-content>
            <!-- Edit name-->
            <div v-if="!editModeName" class="mb-2">
              <input
                  readonly
                  type="text"
                  class="input"
                  id="new-name"
                  v-model="newName"
                  v-autowidth="{ minWidth: '75%' }"
              />
              <EditButton @click="toggleEditModeName"></EditButton>
            </div>
            <div v-else class="mb-2">
              <input
                  type="text"
                  class="input text-black"
                  id="new-name"
                  v-model="newName"
                  v-autowidth="{ minWidth: '75%' }"
              />
              <SaveButton @click="updateName"></SaveButton>
              <CloseButton @click="toggleEditModeName"></CloseButton>
            </div>

            <!-- Edit email -->
            <div v-if="!editModeEmail" class="mb-2">
              <input
                  readonly
                  type="email"
                  class="input"
                  id="new-email"
                  v-model="newEmail"
                  v-autowidth="{ minWidth: '75%' }"
              />
              <EditButton @click="toggleEditModeEmail"></EditButton>
            </div>
            <div v-else>
              <input
                  type="text"
                  class="input text-black"
                  id="new-email"
                  v-model="newEmail"
                  v-autowidth="{ minWidth: '75%' }"
              />
              <SaveButton @click="updateEmail"></SaveButton>
              <CloseButton @click="toggleEditModeEmail"></CloseButton>
            </div>

            <!-- Daily email notification -->
            <div class="mt-3 mb-2">
              <label for="daily-email-notification">
                <input
                    type="checkbox"
                    id="daily-email-notification"
                    v-model="dailyEmailNotification"
                    @change="updateDailyEmailNotification"
                />
                {{ $t("card.notification") }}
              </label>
            </div>

            <ManageTeamsButton></ManageTeamsButton>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <!-- Edit password -->
    <ion-row>
      <ion-col>
        <ion-card lines="full" v-if="profileLoaded">
          <ion-card-header class="text-xl uppercase">{{ $t("card.pw") }}</ion-card-header>
          <ion-card-content>
            <div v-if="!editModePassword" class="mb-2">
              <input
                  readonly
                  type="password"
                  class="input text-black"
                  id="new-pw"
                  placeholder="************"
              />
              <EditButton @click="toggleEditModePassword"></EditButton>
            </div>
            <div v-if="editModePassword" class="mb-2">
              <input
                  type="password"
                  class="input text-black mb-1"
                  id="new-pw"
                  v-model="newPassword"
                  v-autowidth="{ minWidth: '75%' }"
              />
              <p>New Password</p>
              <div class="mb-2 mt-3">
                <input
                    type="password"
                    class="input text-black mb-1"
                    id="pw-confirm"
                    v-model="passwordConfirm"
                    v-autowidth="{ minWidth: '75%' }"
                />
                <p>Repeat Password</p>
                <SaveButton class="mt-3" @click="savePassword"></SaveButton>
                <CloseButton @click="toggleEditModePassword"></CloseButton>
              </div>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <!-- Feedback button -->
    <div class="container pl-2 mt-5">
      <a class="ml-1 float-left feedback-btn" href="mailto:feedback@whofeedsthecat.com">Feedback</a>
    </div>

    <!-- Logout -->
    <LogoutButton class="float-right mr-2"></LogoutButton>
  </PageGridLayout>
</template>