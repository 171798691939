<script setup>
import { storeToRefs } from "pinia";
import ManageTeamsButton from "@/views/Components/ManageTeamsButton";
import AlertCard from "@/views/Components/AlertCard";

import {useProfileStore} from "@/store/useProfile";
const profile = useProfileStore();
const {currentTeam,profileLoaded} = storeToRefs(profile);
</script>
<template>

  <!-- Check if team members -->

    <div v-if="profileLoaded && !currentTeam" class="mb-3">
      <AlertCard>
        <template v-slot:title>Du hast noch kein Team.</template>
        <ManageTeamsButton></ManageTeamsButton>
      </AlertCard>
    </div>

</template>