<script setup>
import {defineProps, ref} from "vue";
import {supabase} from "@/supabase/init";
import { PencilIcon } from '@heroicons/vue/solid';

import {useTaskStore} from "@/store/useTasks";
const tasks = useTaskStore();

import {useStatsStore} from "@/store/useStats";

const props = defineProps([
  'id',
  'userId',
  'name',
  'intv',
  'status',
  'editable',
  'clickable'
])

const newName = ref(props.name);
const newIntv = ref(props.intv)
const editMode = ref(false);

const toggleEditMode = () => {
  editMode.value = !editMode.value;
};

const status = ref(props.status);

// Update task status
const updateStatus = async () => {
  const stats = useStatsStore();
  status.value = !status.value;
  if (status.value) {
    await stats.updateScore(props.intv,1)
  } else {
    await stats.updateScore(props.intv,-1)
  }
  try {
    const { error } = await supabase
        .from("tasks")
        .update({
          status: status.value,
        })
        .eq("id", props.id);
    tasks.checkIfAllDone(props.intv)
    if (error) throw error;
  } catch (error) {
    console.log(error);
  }
};

// Update task name and interval
const updateTask = async () => {
  try {
    const { error } = await supabase
        .from("tasks")
        .update({
          name: newName.value,
          intv: newIntv.value
        })
        .eq("id", props.id);
    editMode.value = false;
    newIntv.value = props.intv;
    tasks.getTeamTasks(1);
    tasks.getTeamTasks(7);
    if (error) throw error;
  } catch (error) {
    console.log(error)
  }
};

// Delete task
const deleteTask = async () => {
  try {
    const { error } = await supabase
        .from("tasks")
        .delete()
        .eq("id", props.id);
    editMode.value = false;
    tasks.getTeamTasks(1);
    tasks.getTeamTasks(7);
    if (error) throw error;
  } catch (error) {
    console.log(error)
  }
};
</script>

<template>

  <!--  Not editable and not clickable task
      for "Team" section -->

  <div v-if="!clickable">
    <button v-if="!status" class="bg-gray-50 text-black font-medium hover:bg-gray-100 mb-1 mt-1 py-2 px-3 rounded-md">
      {{name}}
    </button>
    <button v-else class="bg-gray-50 text-black font-medium hover:bg-gray-100 mb-1 mt-1 py-2 px-3 rounded-md line-through">
      {{name}}
    </button>
  </div>

  <div v-else>

    <!--  Clickable but not editable task
          for "Home" section -->

    <div v-if="!editable">
      <button v-if="!status"
              @click="updateStatus"
              class="bg-brand text-white font-medium hover:bg-brand-hover mb-1 mt-1 py-2 px-3 rounded-md">
        {{name}}
      </button>

      <button v-else
              @click="updateStatus"
              class="bg-light-grey text-black font-medium hover:bg-gray-100 mb-1 mt-1 py-2 px-3 rounded-md line-through">
        {{name}}
      </button>
    </div>

    <!--  Editable but not clickable task
          for "Manage Tasks" section -->

    <div v-else>

      <!-- Editmode off -->

      <div v-if="!editMode" class="flex flex-row">
        <button @click="deleteTask" class="bg-red-600 text-white font-medium hover:bg-red-700 mb-1 mt-1 mr-1 py-2 px-3 rounded-md">x </button>
        <button @click="toggleEditMode"
                class="flex space-x-2 items-center bg-brand text-white font-medium hover:bg-brand-hover mb-1 mt-1 py-2 px-3 rounded-md">
          {{name}}
          <PencilIcon class="h-4 w-4 ml-2"></PencilIcon>
        </button>
      </div>

      <!-- Editmode on -->

      <div v-else class="mb-3">
        <form @submit.prevent="updateTask">
          <input
              type="text"
              class="input"
              id="task-name"
              v-model="newName"
          />
          <select
              id="task-intv"
              class="shadow mr-2 p-2 text-gray-500 border-2 focus:outline-none"
              v-model="newIntv"
          >
            <option disabled value="select-workout">Wähle aus</option>
            <option value="1">Täglich</option>
            <option value="7">Wöchentlich</option>
          </select>
          <button
              type="submit"
              class="update-btn"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>