export default {
    de: {
        card: {
            today: 'Heute',
            thisWeek: 'Diese Woche',
            noDailyTasks: 'Du hast noch keine täglichen Aufgaben.',
            noWeeklyTasks: 'Du hast noch keine täglichen Aufgaben.',
            manageTasks: 'Aufgaben verwalten',
            stats: 'Statistik',
            days: 'Tage',
            weeks: 'Wochen',
            mondaySunday: 'Mo - So',
            daysInRow: 'Erfolgreiche Tage in Folge',
            daysTotal: 'Tage seit Beginn',
            daysPerc: 'Erfolgreiche Tage',
            weeksInRow: 'Erfolgreiche Wochen in Folge',
            weeksTotal: 'Wochen seit Beginn',
            weeksPerc: 'Erfolgreiche Wochen',
            tasksDone: 'Erledigte Aufgaben',
            manageTeams: 'Teams verwalten',
            teamNoDailyTasks: 'Dein Team hat noch täglichen keine Aufgaben.',
            teamNoWeeklyTasks: 'Dein Team hat noch keine wöchentlichen Aufgaben.',
            yourAccount: 'Dein Account',
            notification: 'Tägliche Email-Benachrichtigung',
            pw: 'Passwort',
        },
        manageTasks: {
            dailyTasks: 'Tägliche Aufgaben',
            remixDaily: 'werden jeden Tag um 0:00 (UTC+1) neu gemischt.',
            weeklyTasks: 'Wöchentliche Aufgaben',
            remixWeekly: 'werden jeden Montag um 0:00 (UTC+1) neu gemischt.',
            placeholderDaily: 'Neue tägliche Aufgabe',
            placeholderWeekly: 'Neue wöchentliche Aufgabe',
        },
        manageTeams: {},
        login: {
            login: 'Anmelden',
            noAccount: 'Du hast noch kein Konto?',
            noAccountLink: 'Dann erstelle jetzt eins',
            magicLinkText: 'Passwort vergessen?',
            magicLink: 'Login Link senden',
        },
        register: {
            password: 'Passwort',
            confirmPassword: 'Passwort bestätigen',
            privacyPolicyLink: 'Datenschutzerklärung',
            privacyPolicyRead: 'gelesen und zugestimmt',
            register: 'Registrieren',
            alreadyAccount: 'Du hast schon ein Konto?',
            login: 'Melde dich an',
        },
    },
    en: {
        card: {
            today: 'Today',
            thisWeek: 'This week',
            noDailyTasks: 'You do not have any daily tasks yet.',
            noWeeklyTasks: 'You do not have any weekly tasks yet.',
            manageTasks: 'Manage tasks',
            stats: 'Statistics',
            days: 'Days',
            weeks: 'Weeks',
            mondaySunday: 'Mon - Sun',
            daysInRow: 'Successful days in a row',
            daysTotal: 'Days total',
            daysPerc: 'Successful days',
            weeksInRow: 'Successful weeks in a row',
            weeksTotal: 'Weeks total',
            weeksPerc: 'Successful weeks',
            tasksDone: 'Tasks total',
            manageTeams: 'Manage teams',
            teamNoDailyTasks: 'Your team has no daily tasks yet.',
            teamNoWeeklyTasks: 'Your team has no weekly tasks yet.',
            yourAccount: 'Your account',
            notification: 'Daily email notifictaion',
            pw: 'Password',
        },
        manageTasks: {
            dailyTasks: 'Daily tasks',
            remixDaily: 'will be remixed daily @ 0:00 (UTC+1)',
            weeklyTasks: 'Wöchentliche Aufgaben',
            remixWeekly: 'will be remixed on mondays @ 0:00 (UTC+1)',
            placeholderDaily: 'New daily task',
            placeholderWeekly: 'New weekly task',
        },
        manageTeams: {

        },
        login: {
            login: 'Login',
            noAccount: 'Not registered yet?',
            noAccountLink: 'Create account',
            magicLinkText: 'Forgot password?',
            magicLink: 'Send login link',
        },
        register: {
            createAccount: 'Create account',
            password: 'Password',
            confirmPassword: 'Confirm password',
            privacyPolicyLink: 'Privacy policy',
            privacyPolicyRead: 'read and agreed',
            register: 'Register',
            alreadyAccount: 'You already have an account?',
            login: 'Log in',
        },
    }
}