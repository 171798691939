<script setup>
import {defineEmits, ref} from "vue";
import {IonModal, IonButton, IonButtons, IonHeader, IonToolbar, IonTitle, IonSpinner} from '@ionic/vue';
import { PlusCircleIcon } from '@heroicons/vue/solid';
import {supabase} from "@/supabase/init";

import hrrs from "human-readable-random-string";

import {useToast} from "vue-toastification";
const toast = useToast();

const emit = defineEmits(['reloadData']);

// Create team modal
const createTeamModal = ref(false);
const setCreateTeamModal = (state) => {
  createTeamModal.value = state;
}

// Join team modal
const joinTeamModal = ref(false);
const openJoinTeamModal = () => {
  joinTeamModal.value = true;
}
const closeJoinTeamModal = () => {
  console.log("CLOSING");
  joinTeamModal.value = false;
}
const remixing = ref(false);

// Create team
const newTeamName = ref(null);

const createTeam = async () => {
  const newTeamCode = "JOIN-TEAM-" + hrrs(20);
  try {
    const { data: teamData, error: team_error } = await supabase
        .from('teams')
        .insert([
          {
            name: newTeamName.value,
            unique_code: newTeamCode,
            creator: supabase.auth.session().user.id,
          }
        ]);
    const team_id = teamData[0].id;
    const { data: team_user_data, error: team_user_error } = await supabase
        .from('profile_team')
        .insert([
          {
            team_id: team_id,
            profile_id: supabase.auth.session().user.id,
            admin: true,
          },
        ]);
    const { profile_error } = await supabase
        .from('profiles')
        .update({
          current_team: team_id,
        })
        .eq('id', supabase.auth.session().user.id);
    toast("Team erstellt!");
    emit('reloadData');
    setCreateTeamModal(false);
    console.log(team_user_data, team_error, profile_error, team_user_error)
  } catch (error) {
    console.log(error);
  }
}

// Join team
const joinTeamCode = ref(null);

const joinTeam = async () => {
  const toast = useToast();
  try {
    const { data: teamData, error: team_error } = await supabase
        .from('teams')
        .select('*')
        .eq('unique_code', joinTeamCode.value)
        .single()
    if(teamData) {
      const { data: duplicateData, error: duplicateError } = await supabase
          .from('profile_team')
          .select('id')
          .eq('team_id', teamData.id)
          .eq('profile_id', supabase.auth.session().user.id)
          .single()
      if (!duplicateData) {
        const {error: team_user_error} = await supabase
            .from('profile_team')
            .insert([
              {
                team_id: teamData.id,
                profile_id: supabase.auth.session().user.id
              },
            ]);
        const {profile_error} = await supabase
            .from('profiles')
            .update({
              current_team: teamData.id,
            })
            .eq('id', supabase.auth.session().user.id);
        remixing.value = true;
        emit('reloadData');
        remixing.value = false;
        closeJoinTeamModal();
        console.log( team_error, team_user_error, profile_error, duplicateError)
      } else {
        toast("Du bist schon Mitglied dieses Teams!")
      }
    } else {
      toast("Code ist falsch!")
    }
  } catch (error) {
    console.log(error);
  }
}
</script>

<template>
  <!-- Create team modal-->
  <ion-modal :is-open="createTeamModal">
    <ion-header translucent>
      <ion-toolbar>
        <ion-title>Team erstellen</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="setCreateTeamModal(false)">X</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <form
        class="p-3"
        ref="form" @submit.prevent="createTeam">
      <input
          type="text"
          class="p-2 mt-2 mr-3 text-gray-500 border-2 focus:outline-none"
          id="new-team-name"
          placeholder="Team Name"
          v-model="newTeamName"
          required
      />
      <button
          type="submit"
          class="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm p-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Team erstellen
      </button>
    </form>
  </ion-modal>

  <!-- Join team modal-->
  <ion-modal
      :is-open="joinTeamModal"
      @didDismiss="closeJoinTeamModal()"
  >
    <ion-header translucent>
      <ion-toolbar>
        <ion-title>Team beitreten</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeJoinTeamModal()">X</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <template v-if="!remixing">
      <form class="p-3" ref="form" @submit.prevent="joinTeam">
        <input
            type="text"
            class="p-2 mt-2 mr-3 text-gray-500 border-2 focus:outline-none"
            id="team-code"
            placeholder="Code"
            v-model="joinTeamCode"
            required
        />
        <button
            type="submit"
            class="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm p-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Beitreten
        </button>
      </form>
    </template>
    <template v-else>
      <div class="p-5">Aufgaben werden verteilt ...<br><br>
      <IonSpinner></IonSpinner>
      </div>
    </template>
  </ion-modal>

  <!-- Team buttons -->
  <ion-button @click="setCreateTeamModal(true)">
    <PlusCircleIcon class="h-6 w-6 mr-2 text-white-500"/>
    <span class="text-sm">Team erstellen</span>
  </ion-button>
  <ion-button @click="openJoinTeamModal()">
    <PlusCircleIcon class="h-6 w-6 mr-2 text-white-500"/>
    <span class="text-sm">Team beitreten</span>
  </ion-button>
</template>

<style scoped>

</style>