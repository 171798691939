<script setup>
import {ref} from "vue";
import { storeToRefs } from "pinia";
import {IonButton, IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, onIonViewDidEnter,
} from '@ionic/vue';
import { PlusCircleIcon } from '@heroicons/vue/solid';

import { useToast } from "vue-toastification";
const toast = useToast();

import {useTeamStore} from "@/store/useTeam";
const team = useTeamStore();
const {teamCode, teamLoaded} = storeToRefs(team);

const isOpen = ref(false);
const setModal = (state) => {
  isOpen.value = state;
}

// Copy invitation text and code
const copyText = () => {
  const invText =
      "Tritt meinem Team bei WHOFEEDSTHECAT.COM bei.\n" +
      "Gib nach der Registrierung diesen Code im Feld 'Team beitreten' ein: \n\n" + team.teamCode;
  navigator.clipboard.writeText(invText);
  toast("Text in die Zwischenablage kopiert!", { timeout: 5000 });
}

// Copy only code
const copyCode = () => {
  const code = team.teamCode;
  navigator.clipboard.writeText(code);
  toast("Code in die Zwischenablage kopiert!", { timeout: 5000 });
}

onIonViewDidEnter(() => {
  team.getTeam();
})
</script>

<template>

  <ion-button ion-button icon-only @click="setModal(true)">
    <PlusCircleIcon class="h-6 w-6 mr-2 text-white-500"/>
    <span class="text-sm">TEAM MITGLIEDER EINLADEN</span>
  </ion-button>



  <ion-modal
      :is-open="isOpen"
      @didDismiss="setModal(false)"
  >
    <ion-header translucent>
      <ion-toolbar>
        <ion-title>Team Mitglied einladen</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="setModal(false)">X</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <div class="p-10" v-if="teamLoaded">
      Tritt meinem Team bei WHOFEEDSTHECAT.COM bei.<br>
      Gib nach der Registrierung diesen Code im Feld 'Team beitreten' ein:<br><br>
      <b>{{teamCode}}</b><br><br>
      <ion-button @click="copyText()">Einladung kopieren</ion-button>
      <ion-button @click="copyCode()">Nur Code kopieren</ion-button>
    </div>
    <div v-else>
      <ion-spinner></ion-spinner>
    </div>
  </ion-modal>
</template>

<style scoped>
.invCard {
  max-width: 350px;
}
</style>