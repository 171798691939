import { createRouter, createWebHashHistory } from '@ionic/vue-router';

import { supabase } from "../supabase/init";
import HomeComponent from "../views/HomeView.vue";
import LoginComponent from "../views/LoginView.vue";
import RegisterComponent from "../views/RegisterView.vue";
import ResetComponent from "../views/ResetView.vue";
import ManageTasks from "../views/ManageTasksView.vue";
import ManageTeams from "../views/ManageTeamsView.vue";
import TeamComponent from "../views/TeamView.vue";
import AccountComponent from "../views/AccountView.vue";
import SubscriptionComponent from "../views/SubscriptionView.vue";

const routes = [
    {
        path: "",
        name: "Home",
        component: HomeComponent,
        meta: {
            title: "Home",
            auth: true,
        },
    },
    {
        path: "/login",
        name: "Login",
        component: LoginComponent,
        meta: {
            title: "Login",
            auth: false,
        },
    },
    {
        path: "/register",
        name: "Register",
        component: RegisterComponent,
        meta: {
            title: "Register",
            auth: false,
        },
    },
    {
        path: "/reset",
        name: "Reset",
        component: ResetComponent,
        meta: {
            title: "Reset",
            auth: false,
        },
    },
    {
        path: "/team",
        name: "Team",
        component: TeamComponent,
        meta: {
            title: "Team",
            auth: true,
        },
    },
    {
        path: "/account",
        name: "Account",
        component: AccountComponent,
        meta: {
            title: "Account",
            auth: true,
        },
    },
    {
        path: "/manage-tasks",
        name: "ManageTasks",
        component: ManageTasks,
        meta: {
            title: "Manage Tasks",
            auth: true,
        },
    },
    {
        path: "/manage-teams",
        name: "ManageTeams",
        component: ManageTeams,
        meta: {
            title: "Manage Teams",
            auth: true,
        },
    },
    {
        path: "/subscription",
        name: "ManageSubscription",
        component: SubscriptionComponent,
        meta: {
            title: "Manage Subscription",
            auth: true,
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

// Change document titles
router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | Who Feeds The Cat?`;
    next();
});

// Route guard for auth routes
router.beforeEach((to, from, next) => {
    const user = supabase.auth.user();
    if (to.matched.some((res) => res.meta.auth)) {
        if (user) {
            next();
            return;
        }
        next({ name: "Login" });
        return;
    }
    next();
});

export default router;