<template>
  <ion-app>
    <template v-if="appReady">
      <NavigationComponent />
      <ion-router-outlet />
    </template>
  </ion-app>
</template>

<script setup>
import { ref } from 'vue';
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { supabase } from './supabase/init';
import store from './store/index';
import NavigationComponent from '@/views/Layouts/NavigationComponent';

// Create data / vars
const appReady = ref(false);

// Check to see if user is already logged in
const user = supabase.auth.user();

// If user does not exist, need to make app ready
if (!user) {
  appReady.value = true;
}

// Runs when there is an auth state change
// if user is logged in, this will fire
supabase.auth.onAuthStateChange((_, session) => {
  store.methods.setUser(session);
  appReady.value = true;
});
</script>

<style>
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap"); */
</style>