import { defineStore } from 'pinia'
import { supabase } from "../supabase/init";

export const useTeamStore = defineStore('team', {
    state: () => {
        return {
            teamId: 0,
            teamName: "",
            teamCode: 0,
            teamMembers: [],
            teamLoaded: false
        }
    },
    actions: {
        async getTeam () {
            try {
                let {data: data, error} = await supabase
                    .from("teams")
                    .select(`
                id, name, first_login_today, first_login_week, unique_code,
                profile_team (id, team_id, profile_id)
            `)
                    .eq("id",localStorage.getItem("currentTeam"))
                    .single()
                if (error) throw error;
                    this.teamCode = data.unique_code;
                    this.teamName = data.name;
                    this.teamId = data.id;
                    this.teamMembers = data.profile_team;
                    this.teamLoaded = true;
            } catch (error) {
                console.warn(error.message);
            }
        },
    }
})