<script setup>

import {ref} from "vue";
import PageGridLayout from "@/views/Layouts/PageGridLayout";
import Task from "./Components/TaskComponent.vue";
import IonRefresh from "@/views/Components/IonRefresh";
import ManageTasksButton from "@/views/Components/ManageTasksButton";
import ManageTeamsButton from "@/views/Components/ManageTeamsButton";
import NoTeam from "@/views/Components/NoTeamAlert";
import NoTeamMembers from "@/views/Components/NoTeamMembersAlert";
import {  onIonViewWillEnter, IonSpinner, IonCard, IonCardSubtitle, IonCardContent, IonCardHeader, IonRow, IonCol } from '@ionic/vue';
import {supabase} from "@/supabase/init";
import {storeToRefs} from "pinia/dist/pinia.esm-browser";

// const user = supabase.auth.user();

import {useProfileStore} from "@/store/useProfile";
const profile = useProfileStore();
const {profileLoaded} = storeToRefs(profile);

import {useTeamStore} from "@/store/useTeam";
const team = useTeamStore();

const dailyProfiles = ref(null);
const weeklyProfiles = ref(null);
const dailyLoaded = ref(false);
const weeklyLoaded = ref(false);

// Team tasks
const getTeamMembersTasks = async (interval) => {
  const profile = useProfileStore();
  try {
    let {data: data, error} = await supabase
        .from('profile_team')
        .select(`id, profile_id, team_id,
        profiles(id,name, tasks(id, name, status))`)
        .eq('profiles.tasks.intv', interval)
        .eq('team_id', profile.currentTeam)
        .neq('profile_id', profile.id)
        console.log("DATA",data)
    if (error) throw error;
    if(interval === 1) {
      dailyProfiles.value = data;
      dailyLoaded.value = true;
    } else if (interval === 7) {
      weeklyProfiles.value = data;
      weeklyLoaded.value = true;
    }
  } catch (error) {
    console.warn(error.message);
  }
}

const doRefresh = (event) => {
  setTimeout(() => {
    loadData();
    event.target.complete();
  }, 2000);
}

const getAllTeamMembersTasks = () => {
  getTeamMembersTasks(1);
  getTeamMembersTasks(7);
}


const loadData = async () => {
  const loadProfiles = await profile.getProfile();
  const loadTeam = await team.getTeam(loadProfiles);
  await getAllTeamMembersTasks(loadTeam);
};

onIonViewWillEnter(() => {
  loadData();
})

</script>

<template>
  <PageGridLayout>

    <!-- Refresher -->

    <template v-slot:refresher>
     <IonRefresh @ionRefresh="doRefresh($event)"></IonRefresh>
    </template>

    <!--Alerts -->

    <template v-slot:alerts>
      <NoTeam></NoTeam>
      <NoTeamMembers></NoTeamMembers>
    </template>

    <!-- If profile loaded -->

    <template v-if="profileLoaded">

        <ion-row>
            <ion-col v-if="dailyLoaded" size="12" size-sm class="container">
              <template v-if="dailyProfiles.length > 0">
                  <ion-card v-for="profile in dailyProfiles" :key="profile.profiles.name" class="mb-3">
                    <ion-card-header>
                      <ion-card-subtitle class="font-open">
                        {{ $t("card.today") }}
                      </ion-card-subtitle>
                      {{profile.profiles.name}}
                    </ion-card-header>
                    <ion-card-content>
                      <Task
                          v-for="(task, index) in profile.profiles.tasks"
                          :key="index+task.name+task.status"
                          :id="task.id"
                          :name="task.name"
                          :status="task.status"
                          :intv="task.intv"
                          :editable=false
                          :clickable=false
                      >
                      </Task>
                      <ManageTeamsButton></ManageTeamsButton>
                    </ion-card-content>
                  </ion-card>
              </template>
              <template v-else>
                {{ $t("card.teamNoDailyTasks") }}
                <ManageTasksButton></ManageTasksButton>
              </template>
            </ion-col>

            <ion-col v-else size="12" size-sm class="container">
              <ion-spinner></ion-spinner>
            </ion-col>

            <ion-col v-if="weeklyLoaded" size="12" size-sm class="container">
              <template v-if="dailyProfiles.length > 0">
                <ion-card v-for="profile in weeklyProfiles" :key="profile.profiles.name" class="mb-3">
                  <ion-card-header>
                    <ion-card-subtitle class="font-open">
                      {{ $t("card.thisWeek") }}
                    </ion-card-subtitle>
                    {{profile.profiles.name}}
                  </ion-card-header>
                  <ion-card-content>
                    <Task
                        v-for="(task, index) in profile.profiles.tasks"
                        :key="index+task.name+task.status"
                        :id="task.id"
                        :name="task.name"
                        :status="task.status"
                        :intv="task.intv"
                        :editable=false
                    >
                    </Task>
                    <ManageTeamsButton></ManageTeamsButton>
                  </ion-card-content>
                </ion-card>
              </template>
                <template v-else>
                  {{ $t("card.teamNoWeeklyTasks") }}
                  <ManageTasksButton></ManageTasksButton>
                </template>
            </ion-col>
            <ion-col v-else size="12" size-sm class="container">
              <ion-spinner></ion-spinner>
            </ion-col>
        </ion-row>
    </template>
  </PageGridLayout>
</template>