import { defineStore } from 'pinia'
import { supabase } from "../supabase/init";

import {useStatsStore} from "./useStats";

import { useToast } from "vue-toastification";
const toast = useToast();

export const useTaskStore = defineStore('tasks', {
    state: () => {
        return {
            teamId: 0,
            dailyTasks: [],
            weeklyTasks: [],
            dailyTeamTasks: [],
            weeklyTeamTasks: [],
            dailyLoaded: false,
            weeklyLoaded: false,
            dailyTeamLoaded: false,
            weeklyTeamLoaded: false,
        }
    },
    getters: {
    },
    actions: {
        async getTasks(intv) {
            try {
                const {data: tasks, error} = await supabase
                    .from("tasks")
                    .select("*")
                    .eq("profile_id", supabase.auth.session().user.id)
                    .eq("team_id", localStorage.getItem("currentTeam"))
                    .eq("intv", intv)
                    .order('name', {ascending: true});
                if (error) throw error;
                if (intv == 1) {
                    this.dailyTasks = tasks;
                    this.dailyLoaded = true;
                } else {
                    this.weeklyTasks = tasks;
                    this.weeklyLoaded = true;
                }
            } catch (error) {
                console.warn(error.message);
            }
        },
        async getTeamTasks(intv) {
            try {
                const {data: tasks, error} = await supabase
                    .from("tasks")
                    .select("*")
                    .eq("team_id", localStorage.getItem("currentTeam"))
                    .eq("intv", intv)
                    .order('name', {ascending: true});
                if (error) throw error;
                if (intv == 1) {
                    this.dailyTeamTasks = tasks;
                    this.dailyTeamLoaded = true;
                } else {
                    this.weeklyTeamTasks = tasks;
                    this.weeklyTeamLoaded = true;
                }
            } catch (error) {
                console.warn(error.message);
            }
        },
        async checkIfAllDone(interval) {
            await this.getTasks(interval);
            const stats = useStatsStore();
            let allDone = false;
            let tasks;
            if(interval === 1) {
                tasks = this.dailyTasks
            } else {
                tasks = this.weeklyTasks
            }
                for (let task of tasks) {
                    if (task['status'] == false) {
                        allDone = false;
                        break;
                    } else {
                        allDone = true
                    }
                }
            if (interval === 1) {
                if (allDone) {
                    stats.updateAllDoneDaily(1);
                    toast("Juhuu! Alle täglichen Aufgaben erledigt!")
                } else {
                    stats.updateAllDoneDaily(0);
                }
            } else if (interval === 7) {
                if (allDone) {
                    stats.updateAllDoneWeekly(1);
                    toast("Juhuu! Alle wöchentlichen Aufgaben erledigt!")
                } else {
                    stats.updateAllDoneWeekly(0);
                }
            }
        },
        async getAllProfileTasks() {
            this.getTasks(1);
            this.getTasks(7);
        },
        async getAllTeamTasks() {
            // this.dailyTeamLoaded = false;
            // this.weeklyTeamLoaded = false;
            this.getTeamTasks(1);
            this.getTeamTasks(7);
        },
        async getAllTasks() {
            this.getTasks(1);
            this.getTasks(7);
            this.getTeamTasks(1);
            this.getTeamTasks(7);
        },
    }
})