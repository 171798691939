import { defineStore } from 'pinia';
import { supabase } from "../supabase/init";

export const useProfileStore = defineStore('profile', {
    state: () => {
        return {
            id: 0,
            name: '', // Initialize as empty string
            token: '', // Initialize as empty string
            created: '', // Initialize as empty string
            subscription: '', // Initialize as empty string
            dailyEmailNotification: false, // Initialize as boolean
            currentTeam: '', // Initialize as empty string
            firstLogin: false,
            profileLoaded: false,
        }
    },
    actions: {
        async getProfile () {
            try {
                const user = supabase.auth.user();
                if (!user) throw new Error("User not authenticated");

                const { data: profile, error } = await supabase
                    .from("profiles")
                    .select("*")
                    .eq("id", user.id)
                    .single();
                if (error) throw error;

                localStorage.setItem("name", profile.name);
                localStorage.setItem("currentTeam", profile.current_team);

                this.id = profile.id;
                this.name = profile.name;
                this.token = profile.token;
                this.subscription = profile.subscription;
                this.dailyEmailNotification = profile.email_notification;
                this.created = profile.created_at;
                this.currentTeam = profile.current_team;
                this.firstLogin = profile.first_login;
                this.profileLoaded = true;
            } catch (error) {
                console.warn(error.message);
            }
        },
    }
});