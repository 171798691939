<script setup>
import { ref } from "vue";
import { supabase } from "../supabase/init";
import { useRouter } from "vue-router";
import NarrowLayout from "@/views/Layouts/NarrowLayout";

import { useToast } from "vue-toastification";
const toast = useToast();

const router = useRouter();

const email = ref(null);
const password = ref(null);
const name = ref(null);
const confirmPassword = ref(null);
const errorMsg = ref(null);

// Register function
const register = async () => {
  if (password.value === confirmPassword.value) {
    try {
      const { error } = await supabase.auth.signUp({
            email: email.value,
            password: password.value,
          },
          {
            data: {
              email: email.value,
              name: name.value,
            }
          }

      );
      if (error) throw error;
      router.push({ name: "Login" });
      toast("Check your email and confirm your data!")
    } catch (error) {
      errorMsg.value = error.message;
      setTimeout(() => {
        errorMsg.value = null;
      }, 5000);
    }
    return;
  }
  errorMsg.value = "Error: Passwords do not match";
  setTimeout(() => {
    errorMsg.value = null;
  }, 5000);
};

</script>

<template>
  <NarrowLayout>
        <!-- Error Handling -->
        <div v-if="errorMsg" class="mb-10 p-4 rounded-md bg-light-grey shadow-lg">
          <p class="text-red-500">{{ errorMsg }}</p>
        </div>

    <div class="mb-5">
      <img class="mx-auto" width="150" src="../assets/images/who-feeds-the-cat-logo.svg" />
    </div>

        <!-- Register -->
        <form
            @submit.prevent="register"
            class="p-8 flex flex-col bg-light-grey rounded-md shadow-lg"
        >
          <h1 class="text-3xl text-brand mb-4">{{ $t("register.createAccount") }}</h1>

          <div class="flex flex-col mb-2">
            <label for="name" class="mb-1 text-sm text-brand">Name</label>
            <input
                type="text"
                required
                class="p-2 text-gray-500 focus:outline-none"
                id="name"
                v-model="name"
            />
          </div>

          <div class="flex flex-col mb-2">
            <label for="email" class="mb-1 text-sm text-brand">Email</label>
            <input
                type="text"
                required
                class="p-2 text-gray-500 focus:outline-none"
                id="email"
                v-model="email"
            />
          </div>

          <div class="flex flex-col mb-2">
            <label for="password" class="mb-1 text-sm text-brand">{{ $t("register.password") }}</label>
            <input
                type="password"
                required
                class="p-2 text-gray-500 focus:outline-none"
                id="password"
                v-model="password"
            />
          </div>

          <div class="flex flex-col mb-2">
            <label for="confirmPassword" class="mb-1 text-sm text-brand"
            >{{ $t("register.confirmPassword") }}</label
            >
            <input
                type="password"
                required
                class="p-2 text-gray-500 focus:outline-none"
                id="confirmPassword"
                v-model="confirmPassword"
            />
          </div>

          <div class="flex items-center mb-4">
            <input
                type="checkbox"
                required
                class="mr-2 w-4 h-4"
                id="dataProtection"
            />
            <label for="dataProtection" class="mb-1 text-sm text-brand">
              <a href="https://whofeedsthecat.com/datenschutz"><u>{{ $t("register.privacyPolicyLink") }}</u></a> {{ $t("register.privacyPolicyRead") }}
            </label>
          </div>

          <button
              type="submit"
              class="mt-6 py-2 px-6 rounded-sm self-start text-sm
      text-white bg-brand duration-200 border-solid
      border-2 border-transparent hover:border-at-light-green hover:bg-white
      hover:text-brand"
          >
            {{ $t("register.register") }}
          </button>

          <router-link class="text-sm mt-6 text-center" :to="{ name: 'Login' }">
            <span class="text-brand mr-1">{{ $t("register.alreadyAccount") }} </span>
            <span class="underline text-brand"> {{ $t("register.login") }}</span>
          </router-link>
        </form>

  </NarrowLayout>
</template>