import { IonicVue } from '@ionic/vue';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import {createPinia} from "pinia";
import { plugin as VueInputAutowidth } from 'vue-input-autowidth'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const pinia = createPinia();

import { createI18n } from 'vue-i18n'
import messages from './assets/translations.js';
const i18n = createI18n({
        legacy: false,
        locale: 'en',
        fallbackLocale: 'en',
        messages,
})

createApp(App)
    .use(IonicVue)
    .use(router)
    .use(pinia)
    .use(i18n)
    .use(Toast, {
        transition: "Vue-Toastification__bounce",
        maxToasts: 5,
        newestOnTop: false,
        position: "top-right",
        icon: false,
        timeout: 5000,
        hideProgressBar: true,
})
    .use(VueInputAutowidth)
    .mount("#app");

 i18n.global.locale.value = (navigator.language || navigator.userLanguage).substr(0, 2);