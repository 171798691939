<script setup>
import { ref } from "vue";
import { supabase } from "../supabase/init";
import { useRouter } from "vue-router";
import NarrowLayout from "@/views/Layouts/NarrowLayout";

// Create data / vars
const router = useRouter();
const email = ref(null);
const password = ref(null);
const errorMsg = ref(null);

// Login function
const login = async () => {
  try {
    const { error } = await supabase.auth.signIn({
      email: email.value,
      password: password.value,
    });
    if (error) throw error;
    await router.push({ name: "Home" });
  } catch (error) {
    errorMsg.value = `Error: ${error.message}`;
    console.log(error);
    setTimeout(() => {
      errorMsg.value = null;
    }, 5000);
  }
};
</script>

<template>
  <NarrowLayout>

        <!-- Error Handling -->
        <div
            v-if="errorMsg"
            class="mb-10 p-4 rounded-md bg-light-grey shadow-lg"
        >
          <p class="text-red-500">{{ errorMsg }}</p>
        </div>
        <div class="mb-5">
          <img class="mx-auto" width="150" src="../assets/images/who-feeds-the-cat-logo.svg" />
        </div>

        <!-- Login -->
        <form
            @submit.prevent="login"
            class="p-8 flex flex-col bg-light-grey rounded-md shadow-lg"
        >
          <h1 class="text-3xl text-brand mb-4">{{ $t("login.login") }}</h1>

          <div class="flex flex-col mb-2">
            <label for="email" class="mb-1 text-sm text-brand">Email</label>
            <input
                type="text"
                required
                class="p-2 text-gray-500 focus:outline-none"
                id="email"
                v-model="email"
            />
          </div>

          <div class="flex flex-col mb-2">
            <label for="password" class="mb-1 text-sm text-brand"
            >{{ $t("register.password") }}</label
            >
            <input
                type="password"
                required
                class="p-2 text-gray-500 focus:outline-none"
                id="password"
                v-model="password"
            />
          </div>

          <button
              type="submit"
              class="mt-6 py-2 px-6 rounded-sm self-start text-sm text-white bg-brand duration-200 border-solid border-2 border-transparent hover:border-at-light-green hover:bg-white hover:text-brand"
          >
            Login
          </button>

          <router-link
              class="text-sm mt-6 text-center"
              :to="{ name: 'Reset' }"
          >
            <span class="text-brand mr-1">{{ $t("login.magicLinkText") }}</span>
            <span class="underline text-brand">{{ $t("login.magicLink") }}</span>
          </router-link>

          <router-link
              class="text-sm mt-6 text-center"
              :to="{ name: 'Register' }"
          >
            <span class="text-brand mr-1">{{ $t("login.noAccount") }}</span>
            <span class="underline text-brand">{{ $t("login.noAccountLink") }}</span>
          </router-link>
          <div class="text-center text-sm mt-5 text-brand"><a href="https:whofeedsthecat.com">whofeedsthecat.com</a></div>
        </form>
  </NarrowLayout>
</template>