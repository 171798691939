<script setup>
import { ref } from "vue";
import { supabase } from "../supabase/init";
import NarrowLayout from "@/views/Layouts/NarrowLayout";

// Create data / vars
const email = ref(null);
const message = ref(null);
const errorMsg = ref(null);

// Send magic link function
const sendMagicLink = async () => {
  errorMsg.value = null;
  message.value = null;

  const { error } = await supabase.auth.api.sendMagicLinkEmail(
      email.value,
      { redirectTo: 'https://app.whofeedsthecat.com/#/reset' }
  );

  if (error) {
    errorMsg.value = error.message;
  } else {
    message.value = 'Magic link has been sent to your email.';
  }
};
</script>

<template>
  <NarrowLayout>
    <!-- Error Handling -->
    <div v-if="errorMsg" class="mb-10 p-4 rounded-md bg-light-grey shadow-lg">
      <p class="text-red-500">{{ errorMsg }}</p>
    </div>

    <div class="mb-5">
      <img class="mx-auto" width="150" src="../assets/images/who-feeds-the-cat-logo.svg" />
    </div>

    <!-- Send Magic Link -->
    <div>
      <b>Send Magic Link</b>
      <form @submit.prevent="sendMagicLink">
        <label class="mt-6 mr-2" for="email">Email:</label>
        <input class="p-2 text-gray-500 focus:outline-none mt-6" type="email" v-model="email" required /><br>
        <button class="mt-6 py-2 px-6 rounded-sm self-start text-sm text-white bg-brand duration-200 border-solid border-2 border-transparent hover:border-at-light-green hover:bg-white hover:text-brand" type="submit">Send Magic Link</button>
      </form>
      <p v-if="message">{{ message }}</p>
    </div>
  </NarrowLayout>
</template>