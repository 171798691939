<template>

  <!-- Coffee button -->

  <div class="container pl-2 mt-5">
    <a target="_blank" href="https://www.buymeacoffee.com/whofeedsthecat">
      <button class="coffee-btn p-4">
        <div>Buy me a coffee</div>
      </button>
    </a>
  </div>

</template>