<script setup>
import {supabase} from "@/supabase/init";
import PageGridLayout from "@/views/Layouts/PageGridLayout";

const userId = supabase.auth.session().user.id;
</script>
<template>
  <PageGridLayout>
  <!-- Subscribe -->
  <form action="https://api.whofeedsthecat.com/stripe/createCheckoutSession" method="POST">
    <input type="hidden" name="id" id="id" v-model="userId">
    <button type="submit" id="checkout-button">Checkout</button>
  </form>
  <!-- Manage Subscription -->
  <form method="POST" action="https://api.whofeedsthecat.com/stripe/customerPortal">
    <input type="hidden" name="id" id="id" v-model="userId">
    <button type="submit">Manage billing</button>
  </form>
  </PageGridLayout>
</template>