<script setup>

import {ref} from "vue";

import { useToast } from "vue-toastification";
import { storeToRefs } from "pinia";
import {supabase} from "@/supabase/init";

import PageGridLayout from "@/views/Layouts/PageGridLayout";
import TeamModals from './Modals/TeamModals';
import InviteModal from './Modals/InviteModal';
import EditButton from "@/views/Components/EditButton";
import ManageTasksButton from "./Components/ManageTasksButton";

import { IonItem, IonSelect, IonSelectOption,  onIonViewWillEnter, IonSpinner,
  IonCard, IonCardHeader, IonCardContent, IonRow, IonCol, alertController} from '@ionic/vue';

const user = supabase.auth.user();
const toast = useToast();

// User data

import {useProfileStore} from "@/store/useProfile";
const profile = useProfileStore();
const {profileLoaded, currentTeam} = storeToRefs(profile);

// Team data

import {useTeamStore} from "@/store/useTeam";
const team = useTeamStore();
const {teamName, teamLoaded} = storeToRefs(team);
const otherTeams = ref(null);
const otherTeamsLoaded = ref(false);
const teamsCount = ref(0);
const teamsCountLoaded = ref(false);
const reloadingTeam = ref(false);
const newTeamName = ref(teamName);
// const paused = ref(false);

const editModeTeam = ref(false);
const toggleEditModeTeam = () => {
  editModeTeam.value = !editModeTeam.value;
};

const getUserOtherTeams = async() => {
  try {
    let {data: data, error} = await supabase
        .from("profile_team")
        .select(`*,
          teams (
            id, name
          )
        `)
        .eq("profile_id", supabase.auth.session().user.id)
        .neq("team_id", currentTeam.value)
    if (error) throw error;
    otherTeams.value = data;
    otherTeamsLoaded.value = true;
  } catch (error) {
    console.warn(error.message);
  }
};

const countUserTeams = async() => {
  try {
    let {data: data, error} = await supabase
        .from("profile_team")
        .select("*")
        .eq("profile_id", supabase.auth.session().user.id)
    if (error) throw error;
    teamsCount.value = data.length;
    teamsCountLoaded.value = true;
    console.log("T",teamsCount.value);
  } catch (error) {
    console.warn(error.message);
  }
};

const onChangeTeam = async (event) => {
  reloadingTeam.value = true;
  try {
    const { error } = await supabase
        .from("profiles")
        .update({
          current_team: event.target.value,
        })
        .eq("id", user.id);
    if(event.target.value !== 0) {
      loadData();
    }
    reloadingTeam.value = false;
    if (error) throw error;
  } catch (error) {
    console.log(error)
  }
};

const confirmLeaveTeam = async () => {
  const alert = await alertController.create({
    header: 'Bist du sicher?',
    cssClass: 'custom-alert',
    buttons: [
      {
        text: 'Nein',
        cssClass: 'alert-button-cancel',
        handler: () => {
          console.log("No");
        },
      },
      {
        text: 'Ja',
        cssClass: 'alert-button-confirm',
        handler: () => {
          leaveTeam();
        },
      },
    ],
  });
  await alert.present();
};

const leaveTeam = async () => {
  team.teamName = "Kein aktives Team";
  try {
    const { error1 } = await supabase
        .from('profile_team')
        .delete()
        .eq("team_id", team.teamId)
        .eq("profile_id", supabase.auth.session().user.id)
    console.log(error1)
    const { error2 } = await supabase
        .from("profiles")
        .update({
          current_team: 0,
        })
        .eq("id", supabase.auth.session().user.id);
    toast("Team verlassen!")
    setTimeout(loadData(), 1000);
    if (error2) throw error2;
  } catch (error) {
    console.log(error);
  }
}

const updateTeamName = async () => {
  try {
    const { error } = await supabase
        .from("teams")
        .update({
          name: newTeamName.value,
        })
        .eq("id", team.teamId);
    if (error) throw error;
    toggleEditModeTeam();
    loadData();
  } catch (error) {
    console.log(error)
  }
}

// const getPausedMode = async () => {
//   try {
//     const { data,error } = await supabase
//         .from("profile_team")
//         .select("paused")
//         .eq("team_id", team.teamId)
//         .eq("profile_id", supabase.auth.session().user.id)
//         .single()
//     console.log("PAUSED", data)
//         paused.value = data.paused
//     if (error) throw error;
//   } catch (error) {
//     console.log(error)
//   }
// }


// const setPausedMode = async () => {
//   try {
//     const { error } = await supabase
//         .from("profile_team")
//         .update({
//           paused: !paused.value,
//         })
//         .eq("team_id", team.teamId)
//         .eq("profile_id", supabase.auth.session().user.id)
//     if (error) throw error;
//   } catch (error) {
//     console.log(error)
//   }
// }

// Load data
const loadData = async () => {
  const loadProfile = await profile.getProfile();
  const loadTeam = await team.getTeam(loadProfile);
  const loadOtherUserTeams = await getUserOtherTeams(loadTeam);
  // const loadPaused = await getPausedMode();
  await countUserTeams(loadOtherUserTeams);
}

onIonViewWillEnter(() => {
  loadData();
})

</script>

<template>
  <PageGridLayout>

        <!-- Edit teams -->

        <ion-row v-if="profileLoaded && teamLoaded && otherTeamsLoaded && teamsCountLoaded">
          <!-- If > 0 team -->

          <ion-col v-if="teamsCount > 0">
              <ion-card class="mb-3" lines="full">
                <ion-card-header class="text-lg">DEIN TEAM</ion-card-header>
                <ion-card-content>
                  <template v-if="!reloadingTeam">

                    <ion-item v-if="!editModeTeam" color="light" lines="none">
                      <div class="mr-3">{{teamName}}</div>

                      <!-- If > 1 team-->

                      <ion-select interface="popover" @ionChange="onChangeTeam($event)">
                        <ion-select-option disabled>{{ teamName }}</ion-select-option>
                        <ion-select-option
                            v-for="team in otherTeams"
                            :value="team.teams.id"
                            :key="team.teams.id"
                            id="team.teams.id"
                        >
                          {{team.teams.name}}
                        </ion-select-option>
                      </ion-select>
                    </ion-item>

                    <div v-if="!editModeTeam" class="mt-2">
                      <EditButton class="inline-block" @click="toggleEditModeTeam"></EditButton>
                      <button class="inline-block light-btn mt-3 float-right" @click="confirmLeaveTeam">{{ teamName }} verlassen</button>
<!--                      <div class="mt-3">-->
<!--                        <input type="checkbox" id="checkbox" v-model="paused" @click="setPausedMode" class="mr-1">-->
<!--                        <label for="checkbox">Pause</label>-->
<!--                      </div>-->
                    </div>

                    <!-- Edit mode team -->

                    <div v-else>
                      <input
                          type="text"
                          class="input text-black"
                          id="new-team"
                          v-model="newTeamName"
                          v-autowidth="{minWidth: '75%'}"
                      />
                      <button @click="updateTeamName" class="update-btn">Update</button>
                      <button @click="toggleEditModeTeam" class="close-btn">x</button>
                    </div>
                  </template>
                  <ion-item v-else>
                    <ion-spinner></ion-spinner>
                  </ion-item>
                </ion-card-content>
              </ion-card>
          </ion-col>

          <ion-spinner v-if="reloadingTeam"></ion-spinner>

        </ion-row>

        <!-- Invite team-members, join or create team -->

        <div>
          <div v-if="teamsCount === 0" class="mb-3">
            Du hast noch kein Team! Erstelle ein Team oder tritt einem Team bei.
          </div>
          <InviteModal v-if="teamsCount > 0"></InviteModal>
          <TeamModals v-if="teamsCount === 0" @reloadData="loadData"></TeamModals>
          <ManageTasksButton> </ManageTasksButton>
        </div>

  </PageGridLayout>
</template>

<style scoped>
ion-list-header {
  padding-left: 0 !important;
}
.v-align {
  vertical-align: middle;
}
</style>