<script setup>
import {supabase} from "@/supabase/init";
import {useRouter} from "vue-router";
const router = useRouter();

const logout = async () => {
  await supabase.auth.signOut();
  localStorage.removeItem('teamId');
  router.push('/login')
};
</script>

<template>

  <!-- Logout button -->

  <ion-button color="medium" class="mt-1 ml-1" @click="logout">Logout</ion-button>

</template>